import {HttpClient} from '@angular/common/http'
import {Injectable} from '@angular/core'
import {catchError, Observable, throwError, timeout} from 'rxjs'
import {Params} from "../interfaces";
import {ResponseInterface} from "../interfaces/response.interface";


@Injectable({
  providedIn: 'root',
})
export class LocalService {
  constructor(
    private http: HttpClient,
  ) {
    //
  }

  post(url: string, params: Params): Observable<any> {
    return this.http.post<ResponseInterface>(url, params).pipe(
      timeout(12000),
      catchError(error => {
        // if (error.name === 'TimeoutError') {
        //   console.error('İstek zaman aşımına uğradı');
        // }
        return throwError((): any => error);
      })
    );
  }
}
