import {ApplicationConfig, importProvidersFrom, LOCALE_ID} from '@angular/core';
import {provideRouter} from '@angular/router';

import {routes} from './app.routes';
import {AngularFireModule} from "@angular/fire/compat";
import {BrowserModule} from "@angular/platform-browser";
import {FormsModule} from "@angular/forms";
import {AngularFireDatabaseModule} from "@angular/fire/compat/database";
import {IonicStorageModule} from "@ionic/storage-angular";
import {HttpClientModule} from "@angular/common/http";
import {Toast, ToastModule} from "primeng/toast";
import {MessageService} from "primeng/api";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {APP_BASE_HREF, registerLocaleData} from '@angular/common'
import localeTrExtra from '@angular/common/locales/extra/tr'
import {default as localeTr} from '@angular/common/locales/tr'
import {environment} from "../environments/environment";

registerLocaleData(localeTr, 'tr', localeTrExtra)

export const appConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom([
      BrowserModule,
      BrowserAnimationsModule,
      IonicStorageModule.forRoot({name: 'app', storeName: 'solar-panel'}),
      FormsModule,
      AngularFireModule.initializeApp(environment.google.firebase),
      AngularFireDatabaseModule,
      HttpClientModule,
      ToastModule,
    ]),
    provideRouter(routes),
    Toast,
    MessageService,
    {provide: APP_BASE_HREF, useValue: '/'},
    {provide: LOCALE_ID, useValue: 'tr'},
  ]
};
