import {EnvironmentInterface} from "../app/core/interfaces/environment.interface";

export const environment: EnvironmentInterface = {
  title: 'solar-panel',
  version: '2024.09.01.2258',
  production: false,
  environment: 'DEV',
  google: {
    firebase: {
      apiKey: "AIzaSyByh5oZoWhsb47pjPfyHAP6sgT2wy0ddIg",
      authDomain: "solar-panel-dev.firebaseapp.com",
      databaseURL: "https://solar-panel-dev-default-rtdb.europe-west1.firebasedatabase.app",
      projectId: "solar-panel-dev",
      storageBucket: "solar-panel-dev.appspot.com",
      messagingSenderId: "211527407468",
      appId: "1:211527407468:web:dad9c26c36c5e470294549",
      measurementId: "G-QVM1MRJSD9"
    }
  },
  defaults: {
    campusProductionRenewalTimeOut: 1,
    renewalTimeOut: 10,
  },
};
