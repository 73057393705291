import {Routes} from '@angular/router';
import {LayoutMainComponent} from "./layouts/main/main.component";
import {PageNotFoundComponent} from "./core/components/page-not-found/page-not-found.component";

export const routes: Routes = [
  {
    path: '',
    redirectTo: '/dashboard/live',
    pathMatch: 'full'
  },
  {
    path: '',
    component: LayoutMainComponent,
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'setting',
        loadChildren: () => import('./modules/setting/setting.module').then(m => m.SettingModule)
      }
    ]
  },
  {
    path: '**',
    component: LayoutMainComponent,
    children: [
      {
        path: '',
        component: PageNotFoundComponent,
      }
    ]
  }
]
