<p-toolbar #blockCampusProduction styleClass="border-none p-0">
  <div class="flex md:inline-flex align-items-stretch align-content-center flex-wrap gap-2 h-3rem">
    <div
      class="flex align-items-center justify-content-center">
      <i (click)="refresher(true)" class="{{campusProductionStatus}} cursor-pointer"></i>
    </div>
    <div
      class="flex align-items-center justify-content-center text-green-600 bg-green-100 font-medium border-round px-2">
      Anlık: <b>{{ campusProduction?.current|toKwh:0 }}</b>
    </div>
    <div
      class="flex align-items-center justify-content-center text-green-600 bg-green-100 font-medium border-round px-2">
      Bugün: <b>{{ campusProduction?.today|toKwh:0 }}</b>
    </div>
    <div
      class="md:flex align-items-center justify-content-center text-green-600 bg-green-100 font-medium border-round px-2 hidden md:block">
      Dün: <b>{{ campusProduction?.yesterday|toKwh:0 }}</b>
    </div>
    <div
      class="md:flex align-items-center justify-content-center text-green-600 bg-green-100 font-medium border-round px-2 hidden md:block">
      Bu Ay: <b>{{ campusProduction?.month|toKwh:0 }}</b>
    </div>
    <div
      class="md:flex align-items-center justify-content-center text-green-600 bg-green-100 font-medium border-round px-2 hidden md:block">
      Bu Yıl: <b>{{ campusProduction?.year|toKwh:0 }}</b>
    </div>
    <!--    <div-->
    <!--      class="md:flex align-items-center justify-content-center text-green-600 bg-green-100 font-medium border-round px-2 hidden md:block">-->
    <!--      Toplam: <b>{{ campusProduction?.total|toKwh:0 }}</b>-->
    <!--    </div>-->
    <div
      class="md:flex align-items-center justify-content-center px-2">
      <p-inputSwitch (onChange)="onChangeTimeoutAutoRefresh($event)" [(ngModel)]="isCampusProductionAutoRefresh"
                     pTooltip="Otomatik Yenileyi Aç/Kapa"
                     tooltipPosition="bottom"></p-inputSwitch>
    </div>
  </div>
</p-toolbar>
