import {Component, effect, OnDestroy, OnInit, Renderer2} from "@angular/core";
import {AngularFireDatabase, AngularFireObject} from "@angular/fire/compat/database";
import _ from 'lodash'
import {ActivatedRoute, NavigationEnd, Router, RouterLink, RouterOutlet} from "@angular/router";
import {Ripple} from "primeng/ripple";
import {StyleClassModule} from "primeng/styleclass";
import {Params} from "../../core/interfaces";
import {SubSink} from "subsink";
import {CampusStore} from "../../core/stories/campus.store";
import {clearIntervalAsync, setIntervalAsync, SetIntervalAsyncTimer} from "set-interval-async";
import {Storage} from "@ionic/storage-angular";
import {filter, firstValueFrom, map} from "rxjs";
import {ApiService} from "../../core/services/api.service";
import {CampusProductionComponent} from "../../core/components/campus-production/campus-production.component";
import {SolarDatePipe} from "../../core/pipes/solar-date.pipe";
import {DataConvertService} from "../../core/services/data-convert.service";

@Component({
  standalone: true,
  selector: 'layout-main',
  templateUrl: './main.component.html',
  imports: [
    RouterOutlet,
    RouterLink,
    Ripple,
    StyleClassModule,
    CampusProductionComponent
  ],
  providers: [
    SolarDatePipe,
    DataConvertService,
  ]
})
export class LayoutMainComponent implements OnInit, OnDestroy {
  public campusCheckIntervalId: SetIntervalAsyncTimer<[]>
  public campusTracking: boolean = false
  private settingSystem: Params = {}
  private subs = new SubSink()
  private subsStories = new SubSink()

  constructor(
    public router: Router,
    private renderer: Renderer2,
    private activatedRoute: ActivatedRoute,
    private storage: Storage,
    private campusStore: CampusStore,
    private apiService: ApiService,
    private firebaseDB: AngularFireDatabase,
  ) {
    effect(() => {
      this.campusTracking = this.campusStore.tracking()
    })
  }

  async ngOnDestroy() {
    this.subs.unsubscribe()
    this.subsStories.unsubscribe()
    await clearIntervalAsync(this.campusCheckIntervalId)
  }

  async ngOnInit() {
    this.settingSystem = await this.storage.get('settingSystem')
    const tenantId = _.get(this.settingSystem, 'tenantId')
    const campusId = _.get(this.settingSystem, 'campusId')

    if (tenantId && campusId) {

      const firebaseDBRef: AngularFireObject<any> = this.firebaseDB.object(`tenants/${tenantId}/campuses/${campusId}/viewers`)

      this.subsStories.add(
        firebaseDBRef.valueChanges().subscribe((viewers: any) => {
          console.log('viewers', viewers)
          void this.campusStore.set(!!viewers).then()
        })
      )

      await this.campusCheck()
      await this.refresher()

    } else {
      await this.router.navigate(['/', 'setting', 'system']).then()
    }


    this.subs.add(
      this.router.events
        .pipe(
          filter(event => event instanceof NavigationEnd),
          map(() => this.activatedRoute.snapshot),
          map(route => {
            while (route.firstChild) {
              route = route.firstChild
            }
            return route
          })
        )
        .subscribe(() => {
          this.renderer.addClass(document.getElementById('app-sidebar'), 'hidden')
        }),
    )
  }


  async refresher() {
    this.campusCheckIntervalId = setIntervalAsync(async () => {
      await this.campusCheck()
    }, 1000 * 60 * 10)
  }

  async campusCheck() {
    const settingSystem = this.settingSystem
    if (settingSystem?.apiUrl) {
      const url = `${settingSystem.apiUrl}/${settingSystem.tenantId}/campuses/${settingSystem?.campusId}/check`
      await firstValueFrom(this.apiService.get(url, {token: settingSystem?.token, timeout: 3000}))
    }
  }
}
