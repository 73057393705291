import {DatePipe} from '@angular/common'
import {Pipe, PipeTransform} from '@angular/core'
import moment from 'moment'

@Pipe({
  standalone: true,
  name: 'sDate'
})
export class SolarDatePipe extends DatePipe implements PipeTransform {
  override transform(value: string): any {
    return moment(value, 'DD.MM.YY HH:mm:ss').format('YYYY-MM-DDTHH:mm:ss')
  }
}
