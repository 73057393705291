import {Pipe, PipeTransform} from '@angular/core'
import {formatNumber} from '@angular/common'

@Pipe({
  standalone: true,
  name: 'toKwh',
  pure: true,
})
export class ToKwhPipe implements PipeTransform {

  transform(input: number, round: number = 2) {
    // input = _.isString(input) ? _.parseInt(input) : input
    return formatNumber(input / 1000, 'tr', '.' + round + '-' + round)
  }
}
