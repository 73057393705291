import {Injectable} from '@angular/core'
import {Params} from "../interfaces";
import _ from 'lodash'
import {SolarDatePipe} from "../pipes/solar-date.pipe";
import {PlantInterface} from "../interfaces/plant.interface";


@Injectable({
  providedIn: 'root',
})
export class DataConvertService {
  constructor(
    private solarDatePipe: SolarDatePipe,
  ) {
    //
  }

  convert(data: Params, plant: PlantInterface) {

    _.each(data, (items, key: string) => {

      if (key === '701') { // plant zamani
        _.set(plant, 'statistics.time', this.solarDatePipe.transform(items as string))
      }

      if (key === '608') { // invertor basina durum degeri
        plant.devices = _.map(plant.devices, (device) => {
          return {
            ...device,
            statistics: {
              ...device.statistics,
              status: items[device.key],
            }
          }
        })
      }

      if (key === '782') { // invertor basina anlik uretim
        plant.devices = _.map(plant.devices, (device) => {
          return {
            ...device,
            statistics: {
              ...device.statistics,
              yield: items[device.key] ?? 0,
            }
          }
        })
      }

      if (key === '776') { // invertor basina gunluk uretim ve max deger

        const data = items[0]
        const devicesByKey = _.keyBy(plant.devices, 'key');

        let maxValues = _.mapValues(devicesByKey, () => ({value: -Infinity, time: null}));
        let lastCumulativeValues = _.mapValues(devicesByKey, () => -Infinity);

        _.map(data, ([time, values]) => {

          const hour = parseInt(_.split(time, ':')[0])

          _.map(devicesByKey, (device, key) => {
            const deviceData = values[key] || [0, 0];
            const [max = 0, cumulative = 0] = deviceData;

            maxValues[key] = {
              value: Math.max(max, maxValues[key].value),
              time: max > maxValues[key].value ? time : maxValues[key].time
            };

            // bazen 00:00 ile 00:05 saatleri icin cumulative deger dunku degerden arta kalan sekinde olabiliyor bunun icin hour ile duzeltildi
            lastCumulativeValues[key] = Math.max((hour < 3)?0:cumulative, lastCumulativeValues[key]);
          });
        });

        const devices = _.map(plant.devices, (device) => {
          const deviceKey = device.key;
          return {
            ...device,
            statistics: {
              ...device.statistics,
              max: maxValues[deviceKey],
              production: lastCumulativeValues[deviceKey]
            }

          }
        })

        _.set(plant, 'devices', devices)

      }

      if (key === '801') { // plant toplam verileri
        const values = _.get(items, '170')
        _.set(plant, 'statistics.production.current', _.get(values, '101', 0))
        _.set(plant, 'statistics.production.today', _.get(values, '105', 0))
        _.set(plant, 'statistics.production.yesterday', _.get(values, '106', 0))
        _.set(plant, 'statistics.production.month', _.get(values, '107', 0))
        _.set(plant, 'statistics.production.year', _.get(values, '108', 0))
        _.set(plant, 'statistics.production.total', _.get(values, '109', 0))
      }

    })

    return plant;
  }
}
