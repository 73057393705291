import {Injectable, signal, WritableSignal} from '@angular/core'

export interface CampusTrackingInterface {
  status: string,
  time: number,
  value: boolean,
}

@Injectable({
  providedIn: 'root',
})
export class CampusStore {

  tracking: WritableSignal<boolean> = signal<boolean>(false)

  constructor() {
    //
  }

  async set(tracking: boolean) {
    this.tracking.update(() => tracking)
  }
}
