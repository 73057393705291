<div class="layout-main min-h-screen flex surface-ground" id="layout-main">
  <div
    class="bg-gray-900 h-screen hidden flex-shrink-0 absolute left-0 top-0 w-18rem z-2"
    id="app-sidebar">
    <div class="bg-gray-900 flex flex-column fixed top-0 left-0 w-18rem h-full">
      <a [routerLink]="['/','dashboard','live']"
         class="flex align-items-center justify-content-center flex-shrink-0 bg-indigo-500 text-white cursor-pointer"
         pRipple
         style="height:60px">
        <i class="pi pi-sun text-2xl"></i>
      </a>
      <div class="mt-3 select-none">
        <ul class="list-none p-3 lg:py-3 lg:px-2 m-0">
          <li>
            <a [routerLink]="['/','dashboard','live']"
               class="flex align-items-center cursor-pointer p-3 hover:bg-gray-800 border-round text-gray-300 hover:text-white transition-duration-150 transition-colors"
               pRipple>
              <i class="pi pi-bolt mr-2"></i>
              <span class="font-medium">Anlık İzleme</span>
            </a>
          </li>
          <li class="lg:relative">
            <a [hideOnOutsideClick]="true"
               class="flex align-items-center cursor-pointer p-3 lg:justify-content-center hover:bg-gray-800 border-round text-gray-300 hover:text-white transition-duration-150 transition-colors"
               enterFromClass="hidden" leaveToClass="hidden" pRipple pStyleClass="@next">
              <i class="pi pi-cog mr-2 lg:mr-0 text-base lg:text-xl"></i>
              <span class="font-medium inline">Ayarlar</span>
              <i class="pi pi-chevron-down ml-auto"></i>
            </a>
            <ul
              class="list-none pl-3 pr-0 py-0 lg:p-3 m-0 hidden overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out static lg:absolute left-100 top-0 z-1 bg-gray-900 border-round-right shadow-none lg:shadow-2 w-full lg:w-15rem">
              <li>
                <a [routerLink]="['/','setting','system']"
                   class="flex align-items-center cursor-pointer p-3 hover:bg-gray-800 border-round text-gray-300 hover:text-white transition-duration-150 transition-colors"
                   pRipple>
                  <i class="pi pi-bolt mr-2"></i>
                  <span class="font-medium">Sistem</span>
                </a>
              </li>
              <li>
                <a [routerLink]="['/','setting','plant']"
                   class="flex align-items-center cursor-pointer p-3 hover:bg-gray-800 border-round text-gray-300 hover:text-white transition-duration-150 transition-colors"
                   pRipple>
                  <i class="pi pi-bolt mr-2"></i>
                  <span class="font-medium">Plant
                </span>
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <!--      <div class="mt-auto">-->
      <!--        <hr class="mb-3 mx-2 border-top-1 border-none border-gray-800"/>-->
      <!--        <weather-icon></weather-icon>-->
      <!--      </div>-->
    </div>
  </div>
  <div class="min-h-screen flex flex-column relative flex-auto">
    <div
      class="flex justify-content-between align-items-center px-1 md:px-3 surface-section fixed border-bottom-1 surface-border gap-3 z-1 w-full fixed h-5rem">
      <div class="flex justify-content-start flex-grow-1 pl-2x">
        <campus-production></campus-production>
      </div>
      @if (campusTracking) {
        <div class="flex pr-2 md:pr-0">
        <span class="bg-green-500 text-white p-2 border-round">
          <i class="pi pi-cloud-upload text-2xl"></i>
        </span>
        </div>
      }
      <div class="flex pr-2 md:pr-0">
        <button
          [hideOnEscape]="true"
          [hideOnOutsideClick]="true" class="p-link cursor-pointer text-700 block"
          enterActiveClass="fadeinleft" enterFromClass="hidden"
          leaveActiveClass="fadeoutleft"
          leaveToClass="hidden" pRipple pStyleClass="#app-sidebar">
          <i class="pi pi-bars text-4xl"></i>
        </button>
      </div>
    </div>
    <div class="flex flex-column flex-auto mt-8 p-2">
      <div class="border surface-border border-round surface-section flex-auto">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>


