import {Component, OnInit} from '@angular/core';
import {RouterOutlet} from "@angular/router";
import {SubSink} from "subsink";
import {ElectronService} from "./core/services/electron.service";
import {environment} from "../environments/environment";
import {Storage} from "@ionic/storage-angular";
import {ToastModule} from "primeng/toast";


@Component({
  selector: 'app-root',
  standalone: true,
  templateUrl: './app.component.html',
  imports: [
    RouterOutlet,
    ToastModule
  ]
})
export class AppComponent implements OnInit {

  private subs = new SubSink()

  constructor(
    private electronService: ElectronService,
    private storage: Storage,
  ) {
    console.log('AppComponent.environment', environment)


    if (electronService.isElectron) {
      // console.log(process.env);
      console.log('Run in electron')
      // console.log('Electron ipcRenderer', this.electronService.ipcRenderer)
      // console.log('NodeJS childProcess', this.electronService.childProcess);
    } else {
      console.log('Run in browser');
    }
  }

  async ngOnInit() {
    await this.storage.create()
  }
}
