import {Component, effect, OnDestroy, OnInit} from '@angular/core'
import {LocalService} from "../../services/local.service";
import {Storage} from "@ionic/storage-angular";
import {ToolbarModule} from "primeng/toolbar";
import {InputSwitchModule} from "primeng/inputswitch";
import {ToKwhPipe} from "../../pipes/to-kwh.pipe";
import {FormsModule} from "@angular/forms";
import {TooltipModule} from "primeng/tooltip";
import {Params} from "../../interfaces";
import _ from 'lodash'
import {DataConvertService} from "../../services/data-convert.service";
import {StatisticsProductionInterface} from "../../interfaces/statistics.interface";
import {clearIntervalAsync, setIntervalAsync, SetIntervalAsyncTimer} from 'set-interval-async';
import {PlantInterface} from "../../interfaces/plant.interface";
import {catchError, concatMap, from, of, tap, toArray} from "rxjs";
import {EnvironmentInterface} from "../../interfaces/environment.interface";
import {environment} from '../../../../environments/environment';
import {CampusStore} from "../../stories/campus.store";
import {AngularFireDatabase, AngularFireObject} from "@angular/fire/compat/database";

@Component({
  selector: 'campus-production',
  standalone: true,
  templateUrl: './campus-production.component.html',
  imports: [
    ToolbarModule,
    InputSwitchModule,
    ToKwhPipe,
    FormsModule,
    TooltipModule
  ],
})
export class CampusProductionComponent implements OnInit, OnDestroy {

  public isCampusProductionAutoRefresh: boolean = true
  public campusProduction: StatisticsProductionInterface
  public plants: PlantInterface[] = []
  public campusProductionStatus = 'pi pi-spin pi-spinner'
  public environment: EnvironmentInterface = environment
  public campusTracking: boolean = true
  private productionIntervalId: SetIntervalAsyncTimer<[]>
  private firebaseDBRef: AngularFireObject<any> = null

  constructor(
    private localService: LocalService,
    private dataConvertService: DataConvertService,
    private storage: Storage,
    private campusStore: CampusStore,
    private firebaseDB: AngularFireDatabase,
  ) {
    effect(() => {
      this.campusTracking = this.campusStore.tracking()
    })
  }

  async ngOnDestroy() {
    await clearIntervalAsync(this.productionIntervalId)
  }

  async ngOnInit() {
    const settingSystem = await this.storage.get('settingSystem')
    const tenantId = _.get(settingSystem, 'tenantId')
    const campusId = _.get(settingSystem, 'campusId')

    if (tenantId && campusId) {
      this.firebaseDBRef = this.firebaseDB.object(`tenants/${tenantId}/campuses/${campusId}`)
      this.plants = await this.storage.get('plants') as PlantInterface[]

      this.isCampusProductionAutoRefresh = (await this.storage.get('isCampusProductionAutoRefresh')) ?? true

      await this.requests()
      await this.refresher()
    }
  }

  async onChangeTimeoutAutoRefresh(event: any) {
    await this.storage.set('isCampusProductionAutoRefresh', event.checked)
  }

  async requests() {
    await this.production()
  }

  async refresher(clear: boolean = false) {

    if (this.productionIntervalId && clear) {
      await clearIntervalAsync(this.productionIntervalId)
      await this.requests()
    }

    this.productionIntervalId = setIntervalAsync(async () => {
      if (this.isCampusProductionAutoRefresh) {
        await this.requests()
      }
    }, 1000 * 60 * this.environment.defaults.campusProductionRenewalTimeOut)
  }

  async production() {

    this.campusProductionStatus = 'pi pi-exclamation-circle'

    if (this.plants?.length > 0) {

      this.campusProductionStatus = 'pi pi-spin pi-spinner'

      let successCount = 0

      from(this.plants)
        .pipe(
          concatMap((plant, plantIndex: number) => {
              return this.localService.post(plant.address, {"701": null, "801": {"170": null}}).pipe(
                tap((response: Params) => {
                  if (response['701'] !== undefined) {
                    _.set(this.plants, plantIndex, this.dataConvertService.convert(response, plant));
                    successCount++;
                  } else {
                    throw new Error('701');
                  }
                }),
                catchError(() => of(null))
              )
            }
          ),
          toArray()
        )
        .subscribe(() => {
          if (successCount === this.plants.length) {
            this.campusProductionStatus = 'pi pi-check'
            this.campusProduction = {
              current: _.sumBy(this.plants, 'statistics.production.current'),
              today: _.sumBy(this.plants, 'statistics.production.today'),
              yesterday: _.sumBy(this.plants, 'statistics.production.yesterday'),
              month: _.sumBy(this.plants, 'statistics.production.month'),
              year: _.sumBy(this.plants, 'statistics.production.year'),
              total: _.sumBy(this.plants, 'statistics.production.total'),
            }

            if (this.firebaseDBRef && this.campusTracking) {
              void this.firebaseDBRef.update(_.set({}, 'statistics.production', this.campusProduction))
            }

          } else {
            this.campusProductionStatus = 'pi pi-exclamation-circle'
          }
        });

    }
  }
}
