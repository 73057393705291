import {HttpClient} from '@angular/common/http'
import {Injectable} from '@angular/core'
import {catchError, Observable, throwError, timeout} from 'rxjs'
import {environment} from '../../../environments/environment';
import {EnvironmentInterface} from "../interfaces/environment.interface";
import {ResponseInterface} from "../interfaces/response.interface";
import {Params} from "../interfaces";


@Injectable({
  providedIn: 'root',
})
export class ApiService {

  private environment: EnvironmentInterface = environment

  constructor(
    private http: HttpClient,
  ) {
  }

  getNoTimeout(url: string, params: Params): Observable<any> {
    return this.http.get<ResponseInterface>(url, this.params(params))
  }

  get(url: string, params: Params): Observable<any> {
    const to = (params?.timeout ?? 5000) as number
    return this.http.get<ResponseInterface>(url, this.params(params)).pipe(
      timeout(to),
      catchError(error => {
        // if (error.name === 'TimeoutError') {
        //   console.error('İstek zaman aşımına uğradı')
        // }
        return throwError((): any => error)
      })
    )
  }

  private params(params: Params) {
    const token = params?.token ?? '' as string

    const updatedParams = {...params}
    delete updatedParams['token']
    delete updatedParams['timeout']

    const headers = {
      'X-AppVersion': this.environment.version,
      'Authorization': 'Bearer ' + token,
    }

    return {
      params: updatedParams,
      headers: headers
    }
  }
}
