import {Component} from '@angular/core';
import {RouterLink} from "@angular/router";
import {ButtonDirective} from "primeng/button";

@Component({
  standalone: true,
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  imports: [
    RouterLink,
    ButtonDirective
  ]
})
export class PageNotFoundComponent {
}
